import React, { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
} from "../../state";
import { APPLICATION_COLUMNS as columns } from "../../constants/tables";
import { DIRECTOR_APPLICATION_COLUMNS as directorColumns } from "../../constants/tables";
import { useStyles } from "./ApplicationsPage.styles";
import { history } from "../../lib";
import clsx from "clsx";
import { QUOTE_STATUSES, QUOTE_TYPES } from "../../state/auto/types";
import { AddIcon, ExpandLessIcon, ExpandMoreIcon } from "../../components";
import { formatTitleCase } from "../../utils";
import { Agent } from "../../state/admin/state";

function getUniqueAgencies(data: Agent[]) {
  const uniqueAgencies = new Map();
  data.forEach((agent) => {
    if (!uniqueAgencies.has(agent.agency.id && agent.role === "agency_admin")) {
      uniqueAgencies.set(agent.agency.id, agent);
    }
  });
  return Array.from(uniqueAgencies.values()).sort((a, b) =>
    a.agency.name.localeCompare(b.agency.name),
  );
}

export default function ApplicationsPage() {
  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState<string>("");
  const [activeSubStatus, setActiveSubStatus] = useState<string>("");
  const [activeAgency, setActiveAgency] = useState<number>();
  const [activeAgent, setActiveAgent] = useState<string>();
  const classes = useStyles({ activeStatus });
  const applications = useSelector(adminSelectors.applications);

  const agencyId = useSelector(authSelectors.activeAgencyId);
  const sales_director = useSelector(authSelectors.user).sales_director;
  const activeRole = useSelector(authSelectors.activeRole);
  const _agents = useSelector(adminSelectors.agents);
  const uniqueAgencies = getUniqueAgencies(_agents);

  const agents = _agents.filter(
    (a) => a.role === "agency_admin" || a.role === "agent",
  );
  const apiRef = useGridApiRef();

  const [expandedRows, setExpandedRows] = useState<Array<any>>([]);
  const [pageSize, setPageSize] = useState(25);
  const activeColumns =
    activeRole === "sales_admin" || activeRole === "sales_director"
      ? directorColumns
      : columns;
  const isAffiliateAgent = activeRole === "affiliate_agent";
  useEffect(() => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    if (activeAgency) {
      dispatch(
        adminActions.getApplications(activeRole, agencyId, activeAgency),
      );
    } else {
      dispatch(adminActions.getApplications(activeRole, agencyId, false));
    }
  }, [dispatch, activeRole, agencyId, activeAgency]);

  useEffect(() => {
    setActiveAgency(undefined);
  }, [agencyId]);

  useEffect(() => {
    if (!activeRole || !agencyId) return;

    if (activeRole === "sales_admin" || activeRole === "sales_director") {
      dispatch(
        adminActions.getAgents(
          activeRole,
          sales_director[0]?.agency_groups_id,
          agencyId,
        ),
      );
    } else {
      dispatch(adminActions.getAgents(activeRole, undefined, agencyId));
    }
  }, [activeRole]);

  const [rows, setRows] = useState(applications);
  const [amounts, setAmounts] = useState<any>();
  const [quoteType, setQuoteType] = useState("");
  const handleClick = (val) => {
    if (activeStatus === val) {
      setActiveStatus("");
    } else {
      setActiveSubStatus("");
      setActiveStatus(val);
    }
  };

  const handleGroupClick = (currentId, childrenExpanded) => {
    const ids = [...expandedRows];
    if (ids.includes(currentId)) {
      const newRows = ids.filter((id) => id !== currentId);
      setExpandedRows(newRows);
    } else {
      ids.push(currentId);
      setExpandedRows(ids);
    }
    apiRef.current.setRowChildrenExpansion(currentId, !childrenExpanded);
  };

  useEffect(() => {
    const statusesAmount = applications?.reduce((acc, curr) => {
      const currCount = acc[curr["status"]] || 0;
      return {
        ...acc,
        [curr["status"]]: currCount + 1,
      };
    }, {});
    setAmounts(statusesAmount);
  }, [applications, activeAgency]);

  useEffect(() => {
    let newRows = applications;
    if (activeSubStatus) {
      setActiveStatus("");
    }
    if (
      quoteType ||
      activeStatus ||
      activeSubStatus ||
      activeAgency ||
      activeAgent
    ) {
      newRows = applications.filter(
        (a) =>
          (quoteType ? a.quote_type === quoteType : true) &&
          (activeAgent ? a.referrer === activeAgent || !a.referrer : true) &&
          (activeStatus ? a.status === activeStatus : true) &&
          (activeSubStatus === "all"
            ? true
            : activeSubStatus
            ? a.sub_status === activeSubStatus
            : true),
      );
    } else {
      newRows = applications;
    }
    setRows(newRows);
  }, [
    applications,
    activeStatus,
    quoteType,
    activeSubStatus,
    activeAgency,
    activeAgent,
  ]);

  const groupingColDef = {
    minWidth: 250,
    filterable: true,
    disableColumnMenu: false,
    valueGetter({ row }) {
      return row.customerName
        ?.split(" ")
        .map((val) => `${formatTitleCase(val)} `);
    },
    sortable: true,
    renderHeader: () => <div style={{ paddingLeft: 10 }}>Name</div>,
    renderCell: (params) => {
      const { children, childrenExpanded, parent } = params.rowNode;
      return (
        <div className={classes.toggle}>
          <div style={{ color: !parent ? "auto" : "white" }}>
            {params.value} {children?.length ? `(${children.length + 1})` : ""}
          </div>
          {!!children && (
            <div onClick={() => handleGroupClick(params.id, childrenExpanded)}>
              {childrenExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          )}
        </div>
      );
    },
  };

  return (
    <div className={classes.applicationsPage}>
      <div className={classes.pageHeader}>
        <div className={classes.headerTop}>
          <Typography variant="h1">Applications</Typography>
          {!isAffiliateAgent ? (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => history.push("/admin/create-application")}
            >
              Manual Application
            </Button>
          ) : null}
        </div>

        <div className={classes.amountsWrapper}>
          <div
            className={clsx(
              classes.statusSelector,
              activeStatus === "New" ? classes.active : "",
            )}
            onClick={() => handleClick("New")}
          >
            <div className={classes.amount}>
              {(!!amounts && amounts["New"]) || 0}
            </div>
            <span className={classes.statusAmount}>New</span>
          </div>
          <div
            className={clsx(
              classes.statusSelector,
              activeStatus === "In progress" ? classes.active : "",
            )}
            onClick={() => handleClick("In progress")}
          >
            <div className={classes.amount}>
              {(!!amounts && amounts["In progress"]) || 0}
            </div>
            <span className={classes.statusAmount}>In progress</span>
          </div>
          <div
            className={clsx(
              classes.statusSelector,
              activeStatus === "Pending" ? classes.active : "",
            )}
            onClick={() => handleClick("Pending")}
          >
            <div className={classes.amount}>
              {(!!amounts && amounts["Pending"]) || 0}
            </div>
            <span className={classes.statusAmount}>Pending</span>
          </div>
          <div
            className={clsx(
              classes.statusSelector,
              activeStatus === "Closed" ? classes.active : "",
            )}
            onClick={() => handleClick("Closed")}
          >
            <div className={classes.amount}>
              {(!!amounts && amounts["Closed"]) || 0}
            </div>
            <span className={classes.statusAmount}>Closed</span>
          </div>
        </div>
      </div>
      <div className={classes.selectWrapper}>
        {(activeRole === "sales_director" || activeRole === "sales_admin") && (
          <TextField
            className={classes.selectField}
            select
            variant="outlined"
            value={activeAgency}
            onChange={(e) => setActiveAgency(+e.target.value)}
            label="Agencies"
          >
            <MenuItem key={"all"} value={""}>
              All Agencies
            </MenuItem>
            {uniqueAgencies.map((agent) => (
              <MenuItem key={agent.id} value={agent.agency.id}>
                {agent.agency.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {(activeRole === "agency_admin" || activeRole === "agent") &&
          agents.length > 1 && (
            <TextField
              className={classes.selectField}
              select
              variant="outlined"
              value={activeAgent || ""}
              onChange={(e) => setActiveAgent(e.target.value)}
              label="Agent"
            >
              <MenuItem key={"all"} value={""}>
                All Agents
              </MenuItem>
              {agents.map((agent) => (
                <MenuItem key={agent.user.id} value={agent.referral_token}>
                  {agent.user.last_name} {agent.user.first_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        <TextField
          className={classes.selectField}
          select
          variant="outlined"
          value={quoteType}
          onChange={(e) => setQuoteType(e.target.value)}
          label="Application type"
        >
          {QUOTE_TYPES.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.selectField}
          select
          variant="outlined"
          value={activeSubStatus}
          onChange={(e) => setActiveSubStatus(e.target.value)}
          label="Status"
        >
          {QUOTE_STATUSES.map((s) => (
            <MenuItem key={s.sub_status} value={s.sub_status}>
              {s.status} - {s.sub_status}
            </MenuItem>
          ))}
          <MenuItem key={"all-subs"} value={"all"}>
            All
          </MenuItem>
        </TextField>
      </div>
      <div className={classes.applicationsTable}>
        <DataGridPro
          apiRef={apiRef}
          onRowClick={(params) => {
            const id = params.id;
            const hasChildren = !!apiRef.current.getRowGroupChildren({
              groupId: id,
            }).length;
            if (hasChildren) {
              const childrenExpanded = expandedRows.includes(id);
              handleGroupClick(id, childrenExpanded);
            }
          }}
          autoHeight
          rows={rows}
          treeData={true}
          getTreeDataPath={(row) => row.path}
          columns={activeColumns}
          rowsPerPageOptions={[10, 25, 50, 100, 250]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          groupingColDef={groupingColDef}
          getRowClassName={(params) => {
            const classes: any = [];
            if (
              expandedRows.includes(params.id) ||
              params.row.path?.length > 1
            ) {
              classes.push("child-row-styles");
            }
            const hasChildren = !!apiRef.current.getRowGroupChildren({
              groupId: params.id,
            }).length;
            if (hasChildren) {
              classes.push("parent-row");
            }
            return classes;
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              color: "#0033FF",
              boxShadow: "0 2px 20px 0 rgba(141,152,194,0.15)",
              backgroundColor: "#FFFFFF",
            },
          }}
        />
      </div>
    </div>
  );
}
