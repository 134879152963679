import sub from "date-fns/sub";
import { yup } from "../lib";
import { phoneRegExp } from "./form";
import { add } from "date-fns/esm";

const PleaseSelectOption = "Please make a selection";

const validateVehicleType = (item, context) => {
  return !(
    context?.parent?.info?.Type !== "own" &&
    item &&
    item?.indexOf("None") > -1
  );
};

export const startValidationSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  address: yup.object({
    zipcode: yup.string(),
    state: yup.string(),
    city: yup.string(),
    street_line: yup.string(),
    label: yup.string().required("Address is required"),
  }),
});

export const vehicleValidationSchema = yup.object().shape({
  VIN: yup.string(),
  LicensePlate: yup.string(),

  ModelYear: yup.number().required("Vehicle year is required"),
  Maker: yup.string().required("Vehicle make is required"),
  Model: yup.string().required("Vehicle model is required"),
  Trim: yup.string(),

  Type: yup.string().required(PleaseSelectOption),
  miles: yup.number().required("Annual Mileage is required"),
  usage: yup.string().required(PleaseSelectOption),
  moreVehicles: yup
    .boolean()
    .test("moreVehicles", PleaseSelectOption, function (item) {
      const { isLastVehicle } = this.parent;
      if (isLastVehicle) return typeof item !== "undefined";
      return true;
    }),
});

export const driverValidationSchema = yup.object().shape({
  FirstName: yup
    .string()
    .test("FirstName", "First name is required", function (item) {
      const { spouse, isUserEntered } = this.parent;
      if (spouse || isUserEntered) return Boolean(item);
      return true;
    }),
  LastName: yup
    .string()
    .test("LastName", "Last name is required", function (item) {
      const { spouse, isUserEntered } = this.parent;
      if (spouse || isUserEntered) return Boolean(item);
      return true;
    }),
  gender: yup.string().oneOf(["M", "F"]).required("Gender is required"),

  marital_status: yup
    .string()
    .test("marital_status", "Marital status is required", function (item) {
      const { spouse, relationship_to_applicant } = this.parent;
      if (!spouse && relationship_to_applicant !== "S") return Boolean(item);
      return true;
    }),
  relationship_to_applicant: yup
    .string()
    .test(
      "relationship_to_applicant",
      "Relationship is required",
      function (item) {
        const { spouse, isApplicant } = this.parent;
        if (!isApplicant && !spouse) return Boolean(item);
        return true;
      },
    ),
  dob: yup
    .date()
    .max(
      sub(new Date(), { years: 16 }),
      "Driver must be at least 16 years of age",
    )
    .min(sub(new Date(), { years: 120 }), "Invalid date of birth")
    .required("Date of birth is required")
    .typeError("Invalid date of birth"),

  continuous_insurance: yup
    .string()
    .test("continuous_insurance", PleaseSelectOption, function (item) {
      const { isApplicant } = this.parent;
      if (isApplicant) return typeof item !== "undefined";
      return true;
    }),
  no_insurance_reason: yup
    .string()
    .test("no_insurance_reason", PleaseSelectOption, function (item) {
      const { isApplicant, continuous_insurance } = this.parent;
      if (isApplicant && continuous_insurance === "N")
        return typeof item !== "undefined";
      return true;
    }),
  accidents: yup
    .boolean()
    .test("accidents", PleaseSelectOption, function (item) {
      const { isApplicant, insureDriver } = this.parent;
      if (isApplicant || insureDriver) return typeof item !== "undefined";
      return true;
    }),
  tickets: yup.array().of(
    yup.object().shape({
      speed: yup.number().required("Speed is required"),
      date: yup
        .date()
        .max(new Date(), "Invalid date")
        .min(
          sub(new Date(), { years: 5, days: 1 }),
          "Please select date within the last 5 years",
        )
        .required("Date of violation is required")
        .typeError("Invalid date"),
    }),
  ),
  collision: yup
    .boolean()
    .test("collision", PleaseSelectOption, function (item) {
      const { isApplicant, insureDriver } = this.parent;
      if (isApplicant || insureDriver) return typeof item !== "undefined";
      return true;
    }),
  faultAccidents: yup
    .boolean()
    .test("faultAccidents", PleaseSelectOption, function (item) {
      const { isApplicant, insureDriver, accidents } = this.parent;
      if ((isApplicant || insureDriver) && accidents)
        return typeof item !== "undefined";
      return true;
    }),
  dwi: yup.boolean().test("dwi", PleaseSelectOption, function (item) {
    const { isApplicant, insureDriver, accidents } = this.parent;
    if ((isApplicant || insureDriver) && accidents)
      return typeof item !== "undefined";
    return true;
  }),
  moreDrivers: yup
    .boolean()
    .test("moreDrivers", PleaseSelectOption, function (item) {
      const { isLastDriver, spouse } = this.parent;
      if (isLastDriver && !spouse) return typeof item !== "undefined";
      return true;
    }),
  insureDriver: yup
    .boolean()
    .test("insureDriver", PleaseSelectOption, function (item) {
      const { isApplicant } = this.parent;
      if (!isApplicant) return typeof item !== "undefined";
      return true;
    }),
  education_level: yup
    .string()
    .oneOf(["H", "A", "B", "S", "P", "N"])
    .required("Education level is required"),

  industry: yup.string().required("Industry is required"),

  occupation_class: yup.string().required("Occupation is required"),
});

const today = new Date();
today.setHours(0, 0, 0, 0);

export const discountsValidationSchema = yup.object().shape({
  months_coverage: yup
    .number()
    .test("months_coverage", PleaseSelectOption, function (item) {
      const { selectedDrivers, applicantIndex } = this.parent;
      if (
        applicantIndex !== undefined &&
        selectedDrivers[applicantIndex].continuous_insurance === "Y"
      )
        return item !== -1;
      return true;
    }),
  // bodily_injury: yup
  //   .object()
  //   .test("bodily_injury", PleaseSelectOption, function (item) {
  //     const { submittedDrivers } = this.parent;
  //     if (submittedDrivers[0]?.continuous_insurance === "Y")
  //       return Boolean(item?.perAcc !== 0 && item?.perPerson !== 0);
  //     return true;
  //   }),
  residence_owned_rented: yup
    .string()
    .oneOf(["O", "R"])
    .required(PleaseSelectOption),

  whoDrives: yup.array().test("whoDrives", PleaseSelectOption, function (item) {
    const { selectedDrivers } = this.parent;
    return selectedDrivers.length !== 1
      ? !item?.filter(
          (x) => x.driver_id === undefined || x.veh_id === undefined,
        ).length
      : true;
  }),
  effectiveDate: yup
    .date()
    .min(today, "Date cannot be in the past")
    .max(add(new Date(), { days: 30 }), "Cannot be 30 days in advance")
    .required("This field is required")
    .typeError("Invalid date"),
});

export const quotesValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email address is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
});

export const customQuoteValidationSchema = yup.object().shape({
  bi: yup.string().when("biRequired", {
    is: true,
    then: yup.string().required("Bodily injury protection is required"),
  }),
  pd: yup.string().when("pdRequired", {
    is: true,
    then: yup.string().required("Property damage liability is required"),
  }),
  adb: yup.string().when("adbRequired", {
    is: true,
    then: yup.string().required("Accidental death is required"),
  }),
  medex: yup.string().when("medpmRequired", {
    is: true,
    then: yup.string().required("Medical is required"),
  }),
  pip: yup.string().when("pipRequired", {
    is: true,
    then: yup.string().required("Personal injury protection is required"),
  }),
  pipded: yup.string().when("pipDeductibleRequired", {
    is: true,
    then: yup
      .string()
      .required("Personal injury protection deductible is required"),
  }),
  attendantcare: yup.string().when("attendantCareRequired", {
    is: true,
    then: yup.string().required("Attendant care is required"),
  }),
  extendedmedical: yup.string().when("extendedMedicalRequired", {
    is: true,
    then: yup.string().required("Extended medical is required"),
  }),
  medicalexpenseonly: yup.string().when("medicalExpenseOnlyRequired", {
    is: true,
    then: yup.string().required("Medical expense only is required"),
  }),
  um: yup
    .string()
    .test(
      "um",
      "Amount cannot exceed bodily injury protection",
      function (item) {
        const bi = this.parent.bi && JSON.parse(this.parent.bi);
        const um = item && JSON.parse(item);

        if (um) {
          if (
            !bi ||
            Number(bi?.Val1) < Number(um.Val1) ||
            Number(bi?.Val2) < Number(um.Val2)
          )
            return false;
        }
        return true;
      },
    )
    .when("umRequired", {
      is: true,
      then: yup.string().required("Uninsured motorist is required"),
    }),
  umpd: yup
    .string()
    .test(
      "umpd",
      "Amount cannot exceed property damage liability",
      function (item) {
        const pd = this.parent.pd && JSON.parse(this.parent.pd);
        const umpd = item && JSON.parse(item);

        if (umpd) {
          if (
            !pd ||
            Number(pd?.Val1) < Number(umpd.Val1) ||
            Number(pd?.Val2) < Number(umpd.Val2)
          )
            return false;
        }
        return true;
      },
    )
    .when("umpdRequired", {
      is: true,
      then: yup
        .string()
        .required("Uninsured motorist property damage is required"),
    }),

  umpdded: yup
    .string()
    .test(
      "umpdded",
      "Uninsured motorist property damage deductible is required",
      function (item) {
        const { umpd, umpdDeductibleRequired } = this.parent;
        if (umpdDeductibleRequired && umpd && !item) {
          return false;
        }
        return true;
      },
    ),
  vehicles: yup.array().of(
    yup.object().shape({
      coll: yup
        .string()
        .test(
          "coll",
          "Collision is required on non-owned vehicles",
          (item, context) => {
            return validateVehicleType(item, context);
          },
        ),
      comp: yup
        .string()
        .test(
          "comp",
          "Comprehensive is required on non-owned vehicles",
          (item, context) => {
            return validateVehicleType(item, context);
          },
        ),
    }),
  ),
});

export const payInfoValidationSchema = yup.object().shape({
  account_name: yup.string().required("Account Name is required"),
  aba_number: yup
    .string()
    .when("payment_type", (paymentType, schema) =>
      paymentType === "ach"
        ? schema
            .matches(/^\d{9}$/, "Routing number is not valid")
            .required("Routing number is required")
        : schema,
    ),
  acct_number: yup
    .string()
    .when("payment_type", (paymentType, schema) =>
      paymentType === "ach"
        ? schema
            .matches(/^[0-9]*/, "Account number is not valid")
            .required("Account number is required")
        : schema,
    ),
  cc_number: yup.string().when("payment_type", (paymentType, schema) => {
    if (paymentType === "cc") {
      return schema
        .required("Credit card number is required")
        .matches(/^[0-9]*/, "Credit card number is not valid")
        .transform((value) => value.replace(/[^\d]/g, ""))
        .min(15)
        .max(20);
    } else {
      return schema;
    }
  }),
  cvv: yup.string().when("payment_type", (paymentType, schema) => {
    if (paymentType === "cc") {
      return schema
        .required("CVV number is required")
        .matches(/^[0-9]*/, "CVV number is not valid")
        .min(3)
        .max(4);
    } else {
      return schema;
    }
  }),
  expiry_date: yup.mixed().when("payment_type", (paymentType, schema) => {
    if (paymentType === "cc") {
      return yup
        .date()
        .required("Expiration date is required")
        .min(new Date(), "Invalid expiration date")
        .typeError("Invalid expiration date");
    } else {
      return schema;
    }
  }),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  addr1: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
});


export const leasingValidationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  homePhone: yup.string().required("Home Phone is required"),
  cellPhone: yup.string().required("Cell Phone is required"),
  dateOfBirth: yup.date().required("Date of Birth is required"),
  ssn: yup.string().required("SSN is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup
    .string()
    .matches(/^\d{5}$/, "ZIP must be 5 digits")
    .required("ZIP is required"),
  yearsAtAddress: yup.string().required("Years at Address is required"),
  housingStatus: yup.string().required("Housing Status is required"),
  monthlyRentPayment: yup.string().when("housingStatus", {
    is: "Rent",
    then: (schema) => schema.required("Monthly Rent Payment is required"),
    otherwise: (schema) => schema,
  }),
  previousAddress: yup.string(),
  prevCity: yup.string().when("previousAddress", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Previous City is required"),
    otherwise: (schema) => schema,
  }),
  prevState: yup.string().when("previousAddress", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Previous State is required"),
    otherwise: (schema) => schema,
  }),
  prevZip: yup.string().when("previousAddress", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema
        .matches(/^\d{5}$/, "ZIP must be 5 digits")
        .required("Previous ZIP is required"),
    otherwise: (schema) => schema,
  }),
  prevHousingStatus: yup
    .string()
    .required("Previous Housing Status is required"),
  employer: yup.string().required("Employer is required"),
  employerAddress: yup.string().required("Employer Address is required"),
  employerCity: yup.string().required("Employer City is required"),
  employerState: yup.string().required("Employer State is required"),
  employerZip: yup
    .string()
    .matches(/^\d{5}$/, "ZIP must be 5 digits")
    .required("Employer ZIP is required"),
  employerEmail: yup
    .string()
    .email("Invalid email format")
    .required("Employer Email is required"),
  employerPhone: yup
    .string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, "Phone must be in format XXX-XXX-XXXX")
    .required("Employer Phone is required"),
  position: yup.string().required("Position is required"),
  lengthOfEmployment: yup.string().required("Length of Employment is required"),
  annualIncome: yup.string().required("Annual Income is required"),
  coFirstName: yup.string(),
  coMiddleName: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Middle Name is required"),
    otherwise: (schema) => schema,
  }),
  coLastName: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Last Name is required"),
    otherwise: (schema) => schema,
  }),
  coEmail: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema
        .email("Invalid email format")
        .required("Co-Applicant Email is required"),
    otherwise: (schema) => schema,
  }),
  coHomePhone: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Home Phone is required"),
    otherwise: (schema) => schema,
  }),
  coDateOfBirth: yup.date().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Date of Birth is required"),
    otherwise: (schema) => schema,
  }),
  coSsn: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant SSN is required"),
    otherwise: (schema) => schema,
  }),
  coAddress: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Address is required"),
    otherwise: (schema) => schema,
  }),
  coCity: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant City is required"),
    otherwise: (schema) => schema,
  }),
  coState: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant State is required"),
    otherwise: (schema) => schema,
  }),
  coZip: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema
        .matches(/^\d{5}$/, "ZIP must be 5 digits")
        .required("Co-Applicant ZIP is required"),
    otherwise: (schema) => schema,
  }),
  coYearsAtAddress: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Years at Address is required"),
    otherwise: (schema) => schema,
  }),
  coHousingStatus: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema.required("Co-Applicant Housing Status is required"),
    otherwise: (schema) => schema,
  }),
  coMonthlyRentPayment: yup.string().when(["coFirstName", "coHousingStatus"], {
    is: (coFirstName, coHousingStatus) =>
      coFirstName && coHousingStatus === "Rent",
    then: (schema) => schema.required("Monthly Rent Payment is required"),
    otherwise: (schema) => schema,
  }),
  coEmployer: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Employer is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerAddress: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema.required("Co-Applicant Employer Address is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerCity: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Employer City is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerState: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema.required("Co-Applicant Employer State is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerZip: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema
        .matches(/^\d{5}$/, "ZIP must be 5 digits")
        .required("Co-Applicant Employer ZIP is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerEmail: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema
        .email("Invalid email format")
        .required("Co-Applicant Employer Email is required"),
    otherwise: (schema) => schema,
  }),
  coEmployerPhone: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) =>
      schema.required("Co-Applicant Employer Phone is required"),
    otherwise: (schema) => schema,
  }),
  coPosition: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Position is required"),
    otherwise: (schema) => schema,
  }),
  coLengthOfEmployment: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Length of Employment is required"),
    otherwise: (schema) => schema,
  }),
  coAnnualIncome: yup.string().when("coFirstName", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.required("Co-Applicant Annual Income is required"),
    otherwise: (schema) => schema,
  }),
});