import React from "react";
import { Formik, Form, Field } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { TextField } from "formik-mui";
import { useDispatch, useSelector } from "react-redux";
import { autoActions, uiSelectors } from "../../../state";
import { useMobile } from "../../../themes";
import Sidebar from "../../../layouts/sidebar/Sidebar";
import { REACT_APP_ASSETS_URL } from "../../../config";
// import { leasingValidationSchema } from "../../../utils";

export const LeasingWizard = () => {
  const { logo_height, logo_path } = useSelector(uiSelectors.agencyData);
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const initialValues = {
    applicant: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      homePhone: "",
      cellPhone: "",
      dateOfBirth: "",
      ssn: "",
      currentAddress: {
        address: "",
        city: "",
        state: "",
        zip: "",
        yearsAtAddress: "",
        housingStatus: "",
        monthlyRentPayment: "",
      },
      previousAddress: {
        address: "",
        city: "",
        state: "",
        zip: "",
        yearsAtAddress: "",
        housingStatus: "",
        monthlyRentPayment: "",
      },
    },
    employment: {
      employer: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      position: "",
      lengthOfEmployment: "",
      annualIncome: "",
    },
    coApplicant: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      homePhone: "",
      cellPhone: "",
      dateOfBirth: "",
      ssn: "",
      currentAddress: {
        address: "",
        city: "",
        state: "",
        zip: "",
        yearsAtAddress: "",
        housingStatus: "",
        monthlyRentPayment: "",
      },
    },
    coEmployment: {
      employer: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      position: "",
      lengthOfEmployment: "",
      annualIncome: "",
    },
    additionalNotes: "",
  };

  const housingOptions = ["Mortgage", "Rent", "Live w/Family", "Own"];

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(autoActions.submitLeaseForm(values));
    setSubmitting(false);
    resetForm();
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {isMobile && (
        <Box
          sx={{
            padding: "32px 30px",
            "& img": {
              maxWidth: 255,
              height: logo_height || "80px",
            },
          }}
        >
          {logo_path && (
            <Link href="/">
              <img src={`${REACT_APP_ASSETS_URL}/${logo_path}`} alt="logo" />
            </Link>
          )}
        </Box>
      )}
      {!isMobile && (
        <Sidebar mobileOpen={undefined} setMobileOpen={undefined} />
      )}
      <Box
        sx={{
          height: "100vh",
          overflow: "scroll",
          width: "100%",
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            mx: "auto",
            padding: isMobile ? 2 : 8,
            overflow: "hidden",
            maxWidth: 800,
          }}
        >
          <Typography variant="h5" align="center" sx={{ marginBottom: 4 }}>
            Let's get your information
          </Typography>
          <Formik
            initialValues={initialValues}
            // validationSchema={leasingValidationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "8px", fontWeight: 600 }}
                  >
                    PERSONAL INFORMATION
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Field
                        component={TextField}
                        name="applicant.firstName"
                        label="First Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        component={TextField}
                        name="applicant.middleName"
                        label="Middle"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Field
                        component={TextField}
                        name="applicant.lastName"
                        label="Last Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="applicant.email"
                        label="Email"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.homePhone"
                        label="Home Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.cellPhone"
                        label="Cell Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.dateOfBirth"
                        label="Date of Birth"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.ssn"
                        label="SSN"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
                        Current Address (where vehicle is being registered)
                      </Typography>
                      <Field
                        component={TextField}
                        name="applicant.currentAddress.address"
                        label="Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.currentAddress.city"
                        label="City"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.currentAddress.state"
                        label="State"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.currentAddress.zip"
                        label="ZIP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.currentAddress.yearsAtAddress"
                        label="Years at Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Housing Status
                      </Typography>
                      <FormGroup row>
                        {housingOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={
                                  option ===
                                  values.applicant.currentAddress.housingStatus
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    "applicant.currentAddress.housingStatus",
                                    e.target.checked ? option : "",
                                  )
                                }
                              />
                            }
                            label={option}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    {values.applicant.currentAddress.housingStatus ===
                      "Rent" && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="applicant.currentAddress.monthlyRentPayment"
                          label="Monthly Rent Payment"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
                        Previous Address (if current is less than 3 years)
                      </Typography>
                      <Field
                        component={TextField}
                        name="applicant.previousAddress.address"
                        label="Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.previousAddress.city"
                        label="City"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.previousAddress.state"
                        label="State"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="applicant.previousAddress.zip"
                        label="ZIP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="applicant.previousAddress.yearsAtAddress"
                        label="Years at Previous Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Previous Housing Status
                      </Typography>
                      <FormGroup row>
                        {housingOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={
                                  option ===
                                  values.applicant.previousAddress.housingStatus
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    "applicant.previousAddress.housingStatus",
                                    e.target.checked ? option : "",
                                  )
                                }
                              />
                            }
                            label={option}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    {values.applicant.previousAddress.housingStatus ===
                      "Rent" && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="applicant.previousAddress.monthlyRentPayment"
                          label="Monthly Rent Payment"
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "8px", fontWeight: 600 }}
                  >
                    EMPLOYMENT/BUSINESS INFORMATION
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="employment.employer"
                        label="Employer"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="employment.address"
                        label="Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="employment.city"
                        label="City"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="employment.state"
                        label="State"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="employment.zip"
                        label="ZIP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="employment.email"
                        label="Email"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="employment.phone"
                        label="Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="employment.position"
                        label="Position"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="employment.lengthOfEmployment"
                        label="Length of Employment (Years)"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="employment.annualIncome"
                        label="Annual Income"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "8px", fontWeight: 600 }}
                  >
                    CO-APPLICANT INFORMATION
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Field
                        component={TextField}
                        name="coApplicant.firstName"
                        label="First Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        component={TextField}
                        name="coApplicant.middleName"
                        label="Middle"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Field
                        component={TextField}
                        name="coApplicant.lastName"
                        label="Last Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coApplicant.email"
                        label="Email"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coApplicant.homePhone"
                        label="Home Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coApplicant.cellPhone"
                        label="Cell Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coApplicant.dateOfBirth"
                        label="Date of Birth"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coApplicant.ssn"
                        label="SSN"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coApplicant.currentAddress.address"
                        label="Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coApplicant.currentAddress.city"
                        label="City"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coApplicant.currentAddress.state"
                        label="State"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coApplicant.currentAddress.zip"
                        label="ZIP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coApplicant.currentAddress.yearsAtAddress"
                        label="Years at Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Housing Status
                      </Typography>
                      <FormGroup row>
                        {housingOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={
                                  option ===
                                  values.coApplicant.currentAddress
                                    .housingStatus
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    "coApplicant.currentAddress.housingStatus",
                                    e.target.checked ? option : "",
                                  )
                                }
                              />
                            }
                            label={option}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    {values.coApplicant.currentAddress.housingStatus ===
                      "Rent" && (
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="coApplicant.currentAddress.monthlyRentPayment"
                          label="Monthly Rent Payment"
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "8px", fontWeight: 600 }}
                  >
                    CO-APPLICANT EMPLOYMENT/BUSINESS INFORMATION
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coEmployment.employer"
                        label="Employer"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coEmployment.address"
                        label="Address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coEmployment.city"
                        label="City"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coEmployment.state"
                        label="State"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="coEmployment.zip"
                        label="ZIP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coEmployment.email"
                        label="Email"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coEmployment.phone"
                        label="Phone"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coEmployment.position"
                        label="Position"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="coEmployment.lengthOfEmployment"
                        label="Length of Employment (Years)"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="coEmployment.annualIncome"
                        label="Annual Income"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "8px", fontWeight: 600 }}
                  >
                    ADDITIONAL NOTES
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="additionalNotes"
                        label="Additional Notes"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};
