export interface Referrals {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  contact_method: string;
  status: Status;
  created_at: string;
  referring_agent?: ReferringAgent;
  customer: Customer;
  product_data: ProductData;
}

interface ProductData {
  product_name: string;
  referral_category?: {
    name: string;
  };
}

interface Customer {
  agency_id: number;
  agency: Agency;
}

interface Agency {
  name: string;
}

interface ReferringAgent {
  id: number;
  user: ReferralUser;
}

interface ReferralUser {
  first_name: string;
  last_name: string;
}

export interface BaseReferralData {
  referralData: ReferralData;
  productData: FullProductData;
  referringAgent?: FullReferralUser;
  comments?: Comment[];
}

interface FullProductData {
  logo_full_url: string;
  product_name: string;
  logo_path?: string;
  referral_category: ReferralCategory;
}

interface ReferralData {
  id: number;
  email?: string;
  first_name: string;
  last_name: string;
  contact_method: string;
  phone?: string;
  message?: string;
  status: Status;
  created_at: string;
  updated_at?: any;
  deleted_at?: any;
  product_data?: FullProductData;
  referring_agent?: FullReferralUser;
  comments?: Comment[];
  wizard_data?: object;
}

interface Comment {
  id: number;
  message: string;
  status: string;
  created_at: string;
  user: User2;
}

interface FullReferralUser {
  first_name: string;
  last_name: string;
  email: string;
  phone?: any;
}

interface ReferralCategory {
  name: string;
}

interface User2 {
  first_name: string;
  last_name: string;
  email: string;
  phone?: any;
}

export type Status =
  | "New referral"
  | "Contacted"
  | "Presented"
  | "Lost"
  | "Won";

export interface ReferralProduct {
  id: number;
  logo_full_url: string;
  product_name: string;
  is_active: boolean;
  logo_path: string;
  referral_category: ReferralCategory;
  meta: string;
  category_id: number;
}

export interface GoogleCalendar {
  kind: string;
  etag: string;
  id: string;
  name: string;
  timeZone: string;
  color: string;
  backgroundColor: string;
  foregroundColor: string;
  accessRole: string;
  defaultReminders: any[];
  conferenceProperties: any[];
  selected?: boolean;
}

export interface EventItem {
  calName: string;
  summary: string;
  description: string;
  link: string;
  startTime: string;
  endTime: string;
  eventId: string;
  appUrl?: string;
  calendarId: string;
  hangoutLink?: string;
}

export interface AlertItem {
  id: number;
  req_uid?: string;
  user_id: number;
  summary: any;
  description?: any;
  start: string;
  end: string;
  task_quote_alerts: QuoteBaseInfo[];
  complete?: boolean;
  hasTime?: boolean;
  snooze?: boolean;
  event_id?: string;
  calendarId?: string;
  hangoutLink?: string;
  attendees?: [];
}

interface QuoteBaseInfo {
  first_name?: string;
  last_name?: string;
  agency: { domain: string };
}

export interface Application2 {
  client_quotes: ClientQuote[];
}

export interface ClientQuote {
  id: number;
  zip: string;
  city: string;
  addr1: string;
  agent: Agent;
  email: string;
  phone: string;
  state: string;
  status: string;
  req_uid: string;
  referrer?: string;
  agency_id: string;
  last_name: string;
  created_at: string;
  first_name: string;
  sub_status: string;
  agency_name: string;
  quote_types: string;
  last_updated: string;
  udd_request_uuid: string;
}

interface Agent {
  last_name?: string;
  first_name?: string;
}
export function options(width, right) {
  return {
    seriesType: "bars",
    colors: ["#ffefcc", "#dcf8e7", "#d2dcff"],
    annotations: {
      alwaysOutside: true,
      textStyle: {
        color: "black",
        fontName: "Montserrat",
        fontSize: 16,
        bold: true,
      },
      datum: {
        stem: {
          length: 10,
          color: "white",
        },
      },
    },
    bar: { groupWidth: "75%", gap: 12 },
    chartArea: {
      top: 40,
      right: right,
      height: "80%",
      width: `${width}%`,
      backgroundColor: {
        stroke: "#DADFF6",
        strokeWidth: 1,
        height: "95%",
      },
    },
    hAxis: { textStyle: { fontSize: 18, fontFamily: "Montserrat" } },
    titleTextStyle: {
      fontSize: 21,
      fontFamily: "Montserrat",
    },
    vAxis: {
      minValue: 10,
      // maxValue: 1,
      // gridlines: { count: 0 },
      minorGridlines: { count: 0 },
      format: "0",
    },
    legend: {
      position: "bottom",
      textStyle: { fontWeight: 600, fontSize: 16 },
    },
    vAxes: {
      0: {
        title: "Days",
      },
    },
  };
}
export const options2 = {
  seriesType: "bars",
  colors: ["#ffefcc", "#dcf8e7", "#d2dcff"],
  annotations: {
    alwaysOutside: true,
    textStyle: {
      color: "black",
      fontName: "Montserrat",
      fontSize: 16,
      bold: true,
    },
    datum: {
      stem: {
        length: 10,
        color: "white",
      },
    },
  },
  bar: { groupWidth: "75%", gap: 12 },
  chartArea: {
    top: 40,
    // right: 0,
    height: "80%",
    width: "80%",
    backgroundColor: {
      stroke: "#DADFF6",
      strokeWidth: 1,
      height: "95%",
    },
  },
  hAxis: { textStyle: { fontSize: 18, fontFamily: "Montserrat" } },
  titleTextStyle: {
    fontSize: 21,
    fontFamily: "Montserrat",
  },
  vAxis: {
    minValue: 10,
    // maxValue: 1,
    // gridlines: { count: 0 },
    minorGridlines: { count: 0 },
    format: "0",
  },
  legend: { position: "bottom", textStyle: { fontWeight: 600, fontSize: 16 } },
  vAxes: {
    0: {
      title: "Days",
    },
  },
};
export const mobileOptions = {
  seriesType: "bars",
  annotations: {
    alwaysOutside: true,
    textStyle: {
      color: "black",
      fontName: "Montserrat",
      fontSize: 16,
      bold: true,
    },
    datum: {
      stem: {
        length: 10,
        color: "white",
      },
    },
  },
  bar: { gap: 12 },
  chartArea: {
    top: 10,
    right: 10,
    left: 35,
    bottom: 250,
    height: "80%",
    width: "100%",
  },
  hAxis: {
    textStyle: { fontSize: 15, fontFamily: "Montserrat" },
    slantedText: true,
    slantedTextAngle: 68,
  },
  titleTextStyle: {
    fontSize: 21,
    fontFamily: "Montserrat",
  },
  vAxis: {
    minValue: 10,
    minorGridlines: { count: 0 },
    format: "0",
  },
  legend: { position: "none" },
};
export const mobileTimeOptions = {
  seriesType: "bars",
  legend: { position: "bottom", maxLines: 3 },
  colors: ["#ffefcc", "#dcf8e7", "#d2dcff"],
  bar: { groupWidth: "65%" },
  isStacked: true,
  annotations: {
    textStyle: {
      fontName: "Montserrat",
      fontSize: 16,
      bold: true,
    },
    datum: {
      stem: {
        length: 10,
        color: "white",
      },
    },
  },
  chartArea: {
    top: 10,
    right: 10,
    left: 35,
    height: "80%",
    width: "90%",
  },
  hAxis: {
    textStyle: { fontSize: 15, fontFamily: "Montserrat" },
  },
  titleTextStyle: {
    fontSize: 21,
    fontFamily: "Montserrat",
  },
  vAxis: {
    minValue: 10,
    minorGridlines: { count: 0 },
    format: "0",
  },
};
export const barOptions = {
  seriesType: "bars",
  annotations: {
    alwaysOutside: true,
    textStyle: {
      color: "white",
      fontName: "Montserrat",
      fontSize: 16,
      bold: true,
    },
    datum: {
      stem: {
        length: 10,
        color: "white",
      },
    },
  },
  titleTextStyle: {
    fontSize: 21,
    fontFamily: "Montserrat",
  },
  chartArea: {
    left: 200,
    top: 20,
    height: "85%",
    width: "100%",
    backgroundColor: {
      stroke: "#DADFF6",
      strokeWidth: 1,
    },
  },
  hAxis: {
    textStyle: { fontSize: 16, fontFamily: "Montserrat" },
    minorGridlines: { count: 0 },
    minValue: 25,
    format: "0",
  },
  vAxis: {
    textStyle: {
      fontSize: 14,
      fontFamily: "Montserrat",
      textPosition: "in",
    },
  },
  legend: { position: "none" },
};

export const INSURANCE_TYPES = [
  "Auto",
  "Homeowners",
  "Life",
  "Health",
  "Boat",
  "Motorcycle",
  "ATV",
  "Rental Fire",
  "Mobile Home",
  "Flood",
  "Commercial Auto",
  "Workers Compensation",
  "Business Owners",
  "General Liability",
  "Umbrella",
  "Commercial Property",
];

export const responsive = {
  four: {
    breakpoint: { max: 3000, min: 1536 },
    items: 4,
  },
  three: {
    breakpoint: { max: 1536, min: 1240 },
    items: 2,
  },
  two: {
    breakpoint: { max: 1240, min: 600 },
    items: 2,
  },
  one: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};
