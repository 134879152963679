import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { adminActions, authClient, uiActions } from "../states";
import { commercial } from "./state";
const { actions } = commercial;

export const commercialActions = {
  ...actions,
  startApplication(form, req_uid) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        `/commercial/application/${req_uid}/start`,
        { ...form },
      );
      if (status === 204) {
        dispatch(uiActions.setLoading(false));
        Navigation.go(
          AdminPages.continueCommercial.path.replace(":id", req_uid),
        );
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to start application"));
      }
    };
  },
  addDetails(values, req_uid, getQuestions) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/commercial/${req_uid}/details`,
        values,
      );
      if (status === 204) {
        if (getQuestions) {
          Navigation.go(
            AdminPages.commercialQuestions.path.replace(":id", req_uid),
          );
        }
      } else {
        dispatch(uiActions.setLoading(false));
        if (data.message) {
          dispatch(uiActions.showError(data.message));
          return;
        }
        dispatch(uiActions.showError("Failed to update application"));
      }
    };
  },
  submitQuote(values, req_uid) {
    return async (dispatch, getState) => {
      const { applicationFields } = getState().commercial;
      const {
        buildingInfo,
        businessInfo,
        employeeInfo,
        pastPolicyLossInfo,
        employeeInfos,
        owners,
        coverageInfo,
        selectedLiabilityLimits,
      } = applicationFields;
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/commercial/${req_uid}/details`,
        {
          buildingInfo,
          businessInfo,
          employeeInfo,
          pastPolicyLossInfo,
          questions: values,
          employeeInfos,
          owners,
          coverageInfo,
          selectedLiabilityLimits,
          submit: true,
        },
      );
      if (status === 204) {
        Navigation.go(
          AdminPages.quote_mgmt_page.path.replace(":masterQuoteId", req_uid),
        );
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        if (data.message) {
          dispatch(uiActions.showError(data.message));
          return;
        }
        dispatch(uiActions.showError("Failed to submit application"));
      }
    };
  },
  getLegalEntities(req_uid) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/legal-entities`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setEntityTypes(data.legalEntityInfos));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get entity types"));
      }
    };
  },
  getFieldsConfig(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setFieldsConfig([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/fields-config`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setFieldsConfig(data.fieldsConfig));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get fields config"));
      }
    };
  },
  getIndustryCodes() {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setIndustryCodes([]));
      const { data, status } = await authClient.get(
        `/commercial/get-industry-codes`,
      );
      if (status === 200) {
        dispatch(actions.setIndustryCodes(data.naicsCodeInfos));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("Failed to get industry codes"));
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  getJobCodesByState(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setJobCodes([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/get-job-codes`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setJobCodes(data.jobCodeInfos));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get job codes"));
      }
    };
  },
  getCarrierIndustryCodes(req_uid) {
    return async (dispatch) => {
      dispatch(actions.setCarrierIndustryCodes([]));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/${req_uid}/get-carrier-industry-codes`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setCarrierIndustryCodes(data));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get carrier industry codes"));
      }
    };
  },
  getApplicationQuestions(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setQuestions([]));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/questions`,
      );
      if (status === 200) {
        dispatch(actions.setQuestions(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("Failed to get application questions"));
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  getUwStatements(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setUwStatements([]));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/statements`,
      );
      if (status === 200) {
        dispatch(actions.setUwStatements(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get underwriting statements"));
      }
    };
  },
  submitToUnderwriter(quoteId, body, activeRole, agencyId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(
        `/commercial/quote/${quoteId}/refer`,
        body,
      );
      if (data.status === "success") {
        dispatch(adminActions.getQuoteForAgent(quoteId, activeRole, agencyId));
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showSuccess("Quote referred to underwriter"));
      } else {
        dispatch(uiActions.setLoading(false));
        if (data.status === "failed") {
          dispatch(uiActions.showError(data.message));
          return;
        }
        dispatch(uiActions.showError("Failed to submit quote to underwriter"));
      }
    };
  },
  getPaymentPlans(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setPaymentPlans([]));
      const { data, status } = await authClient.get(
        `/commercial/quote/${quoteId}/payment-plans`,
      );
      if (status === 200) {
        dispatch(actions.setPaymentPlans(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get payment plans"));
      }
    };
  },
  getLiabilityLimits(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      dispatch(actions.setLiabilityLimits([]));
      const { data, status } = await authClient.get(
        `/commercial/quote/${quoteId}/liability-limits`,
      );
      if (status === 200) {
        dispatch(actions.setLiabilityLimits(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get liability limits"));
      }
    };
  },
  selectPaymentAndBind(reqUid, planData, activeRole, agencyId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/commercial/quote/${reqUid}/bind`,
        planData,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        if (data.status === "success") {
          dispatch(adminActions.getQuoteForAgent(reqUid, activeRole, agencyId));
        } else {
          // const failures = data.failures;
          dispatch(uiActions.showError("Failed to bind quote"));
        }
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to bind quote"));
      }
    };
  },
  getPolicyDocuments(reqUuid) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, headers, status } = await authClient.get(
        `/commercial/quote/${reqUuid}/policy-documents`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const filenameMatches =
          headers["content-disposition"]?.match(filenameRegex);
        let filename = "Policy.pdf";
        if (filenameMatches && filenameMatches.length >= 2) {
          filename = filenameMatches[1].replace(/['"]/g, "");
        }
        const binaryString = atob(data.data); // Decode base64
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        // Create a blob from the decoded data
        const blob = new Blob([bytes], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to download documents"));
      }
    };
  },
  getApplicationData(quoteId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/application/${quoteId}/data`,
      );
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(actions.setApplicationData(data));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get application data"));
      }
    };
  },
  getIbyndSSORedirectLink(quoteId?: string) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/commercial/ibynd/sso?quoteId=${quoteId || ""}`,
      );
      if (status === 200) {
        window.open(data.url, "_blank");
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get link"));
      }
    };
  },
};
