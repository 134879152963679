import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { ArrowBackIcon, EmailIcon, PhoneIcon } from "../../components/icons";
import { useStyles } from "./ReferralsPage.styles";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { formatPhone, Navigation } from "../../lib";
import { Status } from "../../state/admin/types";
import { useMobile } from "../../themes";
import { LeasingReferralData } from "./LeasingReferralData";

const ReferralsPage = (props: any) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { referralData, productData, referringAgent } = useSelector(
    adminSelectors.referralData,
  );
  const role = useSelector(authSelectors.activeRole);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const userId = useSelector(authSelectors.userId);

  const [currentStatus, setCurrentStatus] = useState<Status>(
    referralData.status,
  );
  const [contactData, setContactData] = useState("");
  const [contactType, setContactType] = useState("");
  const ownerView = role === "referral_product_owner";

  useEffect(() => {
    setCurrentStatus(referralData.status);
  }, [referralData.status]);
  const isLeasingApplication = !!referralData.wizard_data;
const {
  match: {
    params: { referralId },
  },
} = props;

useEffect(() => {
  dispatch(
    adminActions.getReferralData(referralId, role, activeAgencyId, userId),
  );
}, [referralId, role, activeAgencyId, userId]);

const setContactField = (type: "phone" | "email") => {
  setContactType(type);
  if (ownerView && referringAgent) {
    setContactData(referringAgent[type]);
  }
};

const handleStatusChange = (status) => {
  setCurrentStatus(status);
  dispatch(
    adminActions.updateReferralStatus(
      referralId,
      status,
      role,
      activeAgencyId,
      userId,
    ),
  );
};
const statuses: Status[] = [
  "New referral",
  "Contacted",
  "Presented",
  "Won",
  "Lost",
];

return (
  <div>
    <div className={classes.referralsPageHeader}>
      <div
        className={classes.goBack}
        onClick={() => Navigation.go("/admin/referrals")}
      >
        <ArrowBackIcon />
        <p>Back to referrals list</p>
      </div>
      <div>
        <TextField
          select
          variant="standard"
          className={classes.statusDropdown}
          id="status"
          name="status"
          value={currentStatus}
          defaultValue={null}
          onChange={(event) => handleStatusChange(event.target.value)}
          size="small"
          sx={{ minHeight: "36px", height: "36px" }}
          InputProps={{
            disableUnderline: true,
            // className: classes.dropdownText,
          }}
        >
          {statuses.map((status, i) => (
            <MenuItem key={i} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
    <div className={classes.referralsPageDataTable}>
      <div className={classes.referralsPageDataTableLeft}>
        <p className={classes.referralsPageDataTableTitle}>Referral data</p>
        <div className={classes.referralsPageDataTableContent}>
          {ownerView && (
            <div className={classes.flexContainer}>
              <div className={classes.referralsPageDataTableContentFirstRow}>
                <p className={classes.boldText}>Referring agent</p>
                <p>
                  {referringAgent?.first_name} {referringAgent?.last_name}
                </p>
              </div>
              <div className={classes.icons}>
                {!isMobile && (
                  <div>
                    {contactType === "phone" ? (
                      <a href={`tel:${contactData}`}>
                        {formatPhone(contactData)}
                      </a>
                    ) : (
                      <a href={`mailto:${contactData}`}>{contactData}</a>
                    )}
                  </div>
                )}
                <span onClick={() => setContactField("phone")}>
                  <PhoneIcon className={classes.icon} />
                </span>
                <span onClick={() => setContactField("email")}>
                  <EmailIcon className={classes.icon} />
                </span>
              </div>
              {isMobile && (
                <div>
                  {contactType === "phone" ? (
                    <a href={`tel:${contactData}`}>
                      {formatPhone(contactData)}
                    </a>
                  ) : (
                    <a href={`mailto:${contactData}`}>{contactData}</a>
                  )}
                </div>
              )}
            </div>
          )}

          <div className={classes.referralsPageDataTableContentRow}>
            <p className={classes.boldText}>Date submitted</p>
            <p>{new Date(referralData.created_at).toLocaleString()}</p>
          </div>
          <div className={classes.referralsPageDataTableContentRow}>
            <p className={classes.boldText}>Category</p>
            <p>{productData.referral_category.name}</p>
          </div>
          <div className={classes.referralsPageDataTableContentRow}>
            <p className={classes.boldText}>Product</p>
            <p>{productData.product_name}</p>
          </div>
        </div>
      </div>
      <Box className={classes.referralsPageDataTableRight}>
        <p className={classes.referralsPageDataTableTitle}>Prospect data</p>
        {isLeasingApplication ? (
          <Box>
            <LeasingReferralData userData={referralData.wizard_data} />
          </Box>
        ) : (
          <div className={classes.referralsPageDataTableContent}>
            <div className={classes.referralsPageDataTableContentRow}>
              <p className={classes.boldText}>Name</p>
              <p>
                {referralData.first_name} {referralData.last_name}
              </p>
            </div>
            <div className={classes.referralsPageDataTableContentRow}>
              <p className={classes.boldText}>Email</p>
              <p>
                {referralData.email}{" "}
                {referralData.contact_method === "email" && (
                  <span className={classes.preferred}>
                    Preferred: {referralData.contact_method}
                  </span>
                )}
              </p>
            </div>
            <div className={classes.referralsPageDataTableContentRow}>
              <p className={classes.boldText}>Phone</p>
              <p>
                {!!referralData.phone && formatPhone(referralData.phone)}{" "}
                {(referralData.contact_method === "call" ||
                  referralData.contact_method === "text") && (
                  <span className={classes.preferred}>
                    Preferred: {referralData.contact_method}
                  </span>
                )}
              </p>
            </div>
            <div className={classes.referralsPageDataTableContentRow}>
              <p className={classes.boldText}>Message</p>
              <p>{referralData.message}</p>
            </div>
          </div>
        )}
      </Box>
    </div>
    <>
      <div className={classes.referralsPageActivity}>
        <div className={classes.referralsPageActivityHeader}>
          <p>Activity</p>
          <Button
            className={classes.commentButton}
            onClick={() => dispatch(adminActions.setActivityModalOpen(true))}
          >
            Add Comment
          </Button>
        </div>
        {!!referralData.comments?.length &&
          referralData.comments.map((comment) => {
            return (
              <div
                key={comment.created_at + comment.message}
                className={classes.referralsPageActivityRow}
              >
                <p>
                  {comment.user.first_name} {comment.user.last_name}
                </p>
                <p>{new Date(comment.created_at).toLocaleString()}</p>
                <p>{comment.status}</p>
                <p>{comment.message}</p>
              </div>
            );
          })}
      </div>
    </>
  </div>
);
};

export default ReferralsPage;
