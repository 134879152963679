import React from "react";
import {
  Box,
  Typography,
  Grid,
  createTheme,
  ThemeProvider,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Custom MUI theme for a simple, mobile-responsive look
const theme = createTheme({
  palette: {
    text: { primary: "#333", secondary: "#666" },
  },
  typography: {
    h6: { fontWeight: 600 }, // Smaller header for mobile
  },
});

// Utility function to convert field names to readable labels
const toReadableLabel = (fieldName) => {
  return fieldName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};

// Helper function to render fields in a two-column layout with dividers
const renderFields = (obj, prefix = "", _orderedFields = {}) => {
  const fields: React.ReactNode[] = [];
  let hasPreviousValue = false;

  // Define a logical order for fields
  const fieldOrder = {
    firstName: 1,
    middleName: 2,
    lastName: 3,
    dateOfBirth: 4,
    email: 5,
    cellPhone: 6,
    homePhone: 7,
    address: 8,
    city: 9,
    state: 10,
    zip: 11,
    housingStatus: 12,
    yearsAtAddress: 13,
    monthlyRentPayment: 14,
    employer: 15,
    position: 16,
    annualIncome: 17,
    lengthOfEmployment: 18,
    ssn: 19,
  };

  // Sort fields based on the defined order
  const sortedEntries = Object.entries(obj).sort(
    ([keyA], [keyB]) =>
      (fieldOrder[keyA] || Infinity) - (fieldOrder[keyB] || Infinity),
  );

  sortedEntries.forEach(([key, value]) => {
    const fullKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      fields.push(...renderFields(value, fullKey, fieldOrder));
    } else if (value !== "" && value !== null && value !== undefined) {
      if (hasPreviousValue) {
        fields.push(<Divider key={`divider-${fullKey}`} sx={{ my: 0.3 }} />);
      }
      fields.push(
        <Grid container key={fullKey} spacing={0.3} sx={{ mb: 0.3 }}>
          <Grid item xs={5} sm={3}>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: 600 }} // Bolder field names
            >
              {toReadableLabel(key)}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={9}>
            <Typography variant="body2" color="text.secondary">
              {value}
            </Typography>
          </Grid>
        </Grid>,
      );
      hasPreviousValue = true;
    }
  });

  return fields;
};

export const LeasingReferralData = ({ userData }) => {
  // Helper function to check if an object has any non-empty fields
  const hasData = (obj) => {
    return Object.values(obj).some(
      (value) =>
        (typeof value === "object" && value !== null && hasData(value)) ||
        (value !== "" && value !== null && value !== undefined),
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={1}
        sx={{ maxWidth: 1000, margin: "auto", padding: 0.5 }}
      >
        {/* Applicant and Employment Accordion */}
        <Grid item xs={12}>
          <Accordion sx={{ boxShadow: "none", border: "1px solid #ddd" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="applicant-employment-content"
              id="applicant-employment-header"
              sx={{ padding: "4px 8px" }}
            >
              <Typography variant="h6">Applicant & Employment</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 8px 8px" }}>
              <Grid container spacing={1}>
                {/* Applicant Section */}
                {hasData(userData.applicant) && (
                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: "1px solid #ddd", padding: 0.5 }}>
                      <Typography variant="h6" gutterBottom>
                        Applicant
                        <Divider sx={{ mt: 0.3, borderColor: "#ddd" }} />
                      </Typography>
                      {renderFields(userData.applicant)}
                    </Box>
                  </Grid>
                )}
                {/* Employment Section */}
                {hasData(userData.employment) && (
                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: "1px solid #ddd", padding: 0.5 }}>
                      <Typography variant="h6" gutterBottom>
                        Employment
                        <Divider sx={{ mt: 0.3, borderColor: "#ddd" }} />
                      </Typography>
                      {renderFields(userData.employment)}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Co-Applicant and Co-Employment Accordion */}
        <Grid item xs={12}>
          <Accordion sx={{ boxShadow: "none", border: "1px solid #ddd" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="coapplicant-coemployment-content"
              id="coapplicant-coemployment-header"
              sx={{ padding: "4px 8px" }}
            >
              <Typography variant="h6">Co-Applicant & Co-Employment</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 8px 8px" }}>
              <Grid container spacing={1}>
                {/* Co-Applicant Section */}
                {hasData(userData.coApplicant) && (
                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: "1px solid #ddd", padding: 0.5 }}>
                      <Typography variant="h6" gutterBottom>
                        Co-Applicant
                        <Divider sx={{ mt: 0.3, borderColor: "#ddd" }} />
                      </Typography>
                      {renderFields(userData.coApplicant)}
                    </Box>
                  </Grid>
                )}
                {/* Co-Employment Section */}
                {hasData(userData.coEmployment) && (
                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: "1px solid #ddd", padding: 0.5 }}>
                      <Typography variant="h6" gutterBottom>
                        Co-Employment
                        <Divider sx={{ mt: 0.3, borderColor: "#ddd" }} />
                      </Typography>
                      {renderFields(userData.coEmployment)}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Additional Notes Section */}
        {userData.additionalNotes && (
          <Grid item xs={12}>
            <Box sx={{ border: "1px solid #ddd", padding: 0.5 }}>
              <Typography variant="h6" gutterBottom>
                Additional Notes
                <Divider sx={{ mt: 0.3, borderColor: "#ddd" }} />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ lineHeight: 1.3 }}
              >
                {userData.additionalNotes}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default LeasingReferralData;
