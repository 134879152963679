import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  referralsPageHeader: {
    marginTop: 8,
    marginLeft: 24,
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
  },
  goBack: {
    display: "flex",
    alignItems: "center",
    marginRight: 32,
    fontWeight: 500,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& svg": {
      fontSize: 18,
      color: theme.palette.primary.main,
      marginRight: 8,
    },
  },
  statusDropdown: {
    height: 36,
    minHeight: 36,
    paddingLeft: 7,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: "#E5EBFF",
    outline: "none",
    border: "none",
    fontFamily: "Montserrat",
    marginLeft: 24,
    "& > *": {
      height: "36px !important",
    },
  },
  referralsPageDataTable: {
    border: "1px solid rgba(141, 152, 194, .2)",
    margin: "0 40px 48px 24px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      flexDirection: "column",
    },
  },
  referralsPageDataTableLeft: {
    padding: "0 40px",
    width: "50%",
  },
  referralsPageDataTableRight: {
    padding: "0 40px",
    borderLeft: "1px solid rgba(141, 152, 194, .2)",
  },
  referralsPageDataTableTitle: {
    fontSize: 20,
    fontWeight: 500,
    margin: "0 -40px",
    padding: "16px 40px",
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
  },
  referralsPageDataTableContent: {
    padding: "32px 0",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingLeft: 115,
    },
  },
  icons: {
    display: "flex",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: 20,
      marginLeft: 8,
    },
  },
  icon: {
    cursor: "pointer",
  },
  referralsPageDataTableContentRow: {
    display: "grid",
    gridTemplateColumns: "168px 1fr",
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
    fontSize: 16,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  referralsPageDataTableContentFirstRow: {
    display: "grid",
    gridTemplateColumns: "168px 1fr",
    fontSize: 16,
  },
  boldText: {
    fontWeight: 600,
  },
  referralsPageActivity: {},
  referralsPageActivityHeader: {
    borderTop: "1px solid rgba(141, 152, 194, .2)",
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
    fontSize: 20,
    fontWeight: 500,
    margin: "0 0 8px -24px",
    padding: "0 64px",
    display: "flex",
    alignItems: "center",
  },
  commentButton: {
    height: 40,
    width: 180,
    borderRadius: 3,
    color: theme.palette.primary.main,
    background: "#E5EBFF",
    outline: "none",
    border: "none",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    marginLeft: 40,
    cursor: "pointer",
  },
  referralsPageActivityRow: {
    display: "grid",
    gridTemplateColumns: "152px 200px 150px 1fr",
    gridGap: 16,
    paddingLeft: 24,
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(141, 152, 194, .2)",
    },
    "& p:not(:last-child)": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  preferred: {
    display: "inline-block",
    width: 110,
    color: "#0033FF",
    fontFamily: "Montserrat",
    fontSize: 12,
    backgroundColor: "#EFF1FB",
    textAlign: "center",
    borderRadius: 15,
  },
}));
